import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

// const routerData = $store.state.route
//获取路由数据
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    components: { AppPage: () => import("@/views/LoginView.vue") },
  },
  {
    path: "/home",
    name: "home",
    components: { AppPage: () => import("@/views/HomeView.vue") },
    children: [
      {
        path: "/home",
        name: "welcome",
        components: { homepage: () => import("@/views/WelcomeView.vue") },
      },
      {
        path: "/content",
        name: "content",
        components: { homepage: () => import("@/views/ContentView.vue") },
        children: [],
      },
    ],
  },

]
const router = new VueRouter({
  routes,
  mode: "history",
})
// 向 router 上添加一个，重新实例化 VueRouter 的方法
router.reloadRouter = function () {
  router.matcher = new VueRouter({
    mode: "history",
    routes,
  }).matcher
}

//一直点击路由报错解决方案
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

export default router
